import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import "../../styles/CommonStyle.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { travelizeAuth } from "../HeaderAuthentication";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loadDesignationDrop } from "../../redux/action";

function UpdateManagerModel(props) {
  let dispatch = useDispatch();
  const { desigationdrop } = useSelector((state) => state.data);
  const [managerList, setManagerList] = useState([]);
  const [managerID, setManagerID] = useState("");
  const [reportDesignation, setReportDesignation] = useState(0);
  const [fieldReq, setFieldReq] = useState(false);

  useLayoutEffect(() => {
    dispatch(loadDesignationDrop());
  }, []);

  useEffect(() => {
    fetchDropdown();
  }, [reportDesignation]);

  const fetchDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const updateManager = (e) => {
    e.preventDefault();
    if (!reportDesignation || !managerList) {
      setFieldReq(true);
    } else {
      const updateManagerDetails = {
        managerId: managerID,
        userId: props.updateUserId,
        desingation: {
          designationID: reportDesignation,
        },
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(updateManagerDetails),
      };
      fetch(`${baseURL}/api/User/UpdateManager`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.getManagerSubordinates();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            toast.error(res.errorMessage, { autoClose: 5000 });
          }
        });
    }
  };

  return (
    <div>
      <Modal
        show={props.updateManagerModel}
        onHide={props.handleClose}
        className="main_model"
      >
        <form>
          <div className="add-meeting-heading">
            <h6 className="add-lead-head">ASSIGN TO</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>
          <div className="container px-3 py-2">
            <div className="model-add-user-body">
              <div className="mb-3">
                <FloatingLabel
                  label="Designation"
                  className={`${
                    reportDesignation != 0
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    defaultValue={desigationdrop[null]}
                    options={desigationdrop}
                    placeholder="Designation"
                    value={desigationdrop.value}
                    onInputChange={desigationdrop.label}
                    onChange={(data) => setReportDesignation(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && !reportDesignation && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Designation
                  </small>
                )}
              </div>
              <div className="mb-1">
                <FloatingLabel
                  label="Select Manager"
                  className={`${
                    managerID
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    options={managerList}
                    placeholder="Select Manager"
                    value={managerList.value}
                    onInputChange={managerList.label}
                    onChange={(data) => setManagerID(data.value)}
                    isSearchable={true}
                  />
                </FloatingLabel>
                {fieldReq && !managerID && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Manager
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn onClickEffect={updateManager} btnName="Update" />
            <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
          </div>
        </form>
      </Modal>
      <></>
    </div>
  );
}

export default UpdateManagerModel;
