import React, { useState, useEffect, useLayoutEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Clients.scss";
import DatePicker from "react-datepicker";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import {
  optionsMeetingStatusList,
  optionsMeetingTypeList,
} from "../DropdownsData";
import { SkeletonClientHistory } from "../Skeleton Loading/SkeletonClients";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbCancelBtn, { MiClearButton } from "../SbButtons/SbCancelBtn";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import DownloadLoading from "../ReUseComponents/DownloadLoading";
import { travelizeAuth } from "../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadUserDropdown } from "../../redux/action";

function ClientHistory(props) {
  let dispatch = useDispatch();

  const { userDropdown } = useSelector((state) => state.data);

  const [clientHistoryDetails, setClientHistoryDetails] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [clientId, setClientId] = useState(props.clientId);

  const [meetingStatus, setMeetingStatus] = useState("Completed");
  const [meetingType, setMeetingType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );
  const [fromDate, setFromDate] = useState(oneMonthAgo);
  const [toDate, setToDate] = useState(new Date());

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  const setSelectSearchDrop = (dates) => {
    const [start, end] = dates;
    setFromDate(start);
    setToDate(end);
  };

  useLayoutEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  useEffect(() => {
    fetchClientHistory();
  }, [
    filterUserId,
    PageSize,
    currentPage,
    toDate,
    fromDate,
    meetingType,
    meetingStatus,
  ]);

  //  CLIENT HISTORY TABLE START
  const fetchClientHistory = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/GetClientUserHistory?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&status=${meetingStatus}&type=${meetingType}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setClientHistoryDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setClientHistoryDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  //  MEETING PURPOSE TABLE END

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setMeetingType("");
    setMeetingStatus("");
    setFromDate(oneMonthAgo);
    setToDate(new Date());
  };

  const exportUserHistory = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientUserHistoryExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${filterUserId}&ClientID=${clientId}&status=${meetingStatus}&type=${meetingType}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ClientHistory.xlsx",
      setDownloadIsLoading
    );
  };

  return (
    <div id="main-page">
      <>
        {downloadIsLoading && (
          <>
            <DownloadLoading />
          </>
        )}
      </>
      {/* MAIN BODY START */}
      <div>
        {/* PAGE CONTENT START */}
        {props.historyclientmodel ? (
          <div className={`main-body ${props.toggleshift.style}`}>
            <div className="page-content">
              {/* Filter and Header Section */}
              <div className="user-table">
                <Row className="search-row">
                  <Col md={3} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={props.goBacktoClients}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">CLIENTS HISTORY</h6>
                    </div>
                  </Col>
                  <Col md={9} className="cat-col">
                    <div
                      className="search-bar-header"
                      style={{ float: "right" }}
                    >
                      <div className="page-top-bar-dropdowns page-top-Filters">
                        <div
                          className="header-filter-btn"
                          onClick={() => setFilter(!filter)}
                        >
                          <p className="card-head">
                            <i className="bi bi-sliders" />
                            &nbsp;&nbsp;Filters
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                {filter && (
                  <div className="page-filter-section">
                    <div className="filter-row-input-boxes">
                      <div>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={userDropdown}
                          placeholder="Select User"
                          value={filterUserId === "" ? "" : userDropdown.value}
                          onInputChange={userDropdown.label}
                          onChange={(data) => setFilterUserId(data.value)}
                          isSearchable={true}
                        />
                      </div>
                      <div>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={optionsMeetingStatusList}
                          placeholder="Select Status"
                          value={
                            meetingStatus === ""
                              ? ""
                              : optionsMeetingStatusList.value
                          }
                          onInputChange={optionsMeetingStatusList.label}
                          onChange={(data) => setMeetingStatus(data.value)}
                          isSearchable={true}
                        />
                      </div>
                      <div>
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          options={optionsMeetingTypeList}
                          placeholder="Meeting Type"
                          value={
                            meetingType === ""
                              ? ""
                              : optionsMeetingTypeList.value
                          }
                          onInputChange={optionsMeetingTypeList.label}
                          onChange={(data) => setMeetingType(data.value)}
                          isSearchable={true}
                        />
                      </div>
                      <div className="from-to-date-select">
                        <div className="input-group-text" id="from-label">
                          From - To
                        </div>
                        <div className="date-picker-with-icon">
                          <DatePicker
                            selected={fromDate}
                            className="select from-date date-filter"
                            id="date-range-picker"
                            dateFormat="dd/MM/yyyy"
                            selectsRange
                            startDate={fromDate}
                            endDate={toDate}
                            onChange={(date) => setSelectSearchDrop(date)}
                          />
                          <div>
                            <i
                              className="fa fa-calendar-o calander-icon"
                              style={{ right: "6px" }}
                            />
                          </div>
                        </div>
                      </div>

                      <MiClearButton
                        onClickEffect={clearSerachBySelect}
                        btnName="Clear"
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* Filter and Header Section */}

              {/* USER TABLE START */}
              <div className="clients-table">
                <div
                  className="table-responsive clients-scroll"
                  onScroll={(e) =>
                    handleScroll(
                      e,
                      clientHistoryDetails,
                      totalData,
                      setLoading,
                      setPageSize,
                      PageSize
                    )
                  }
                >
                  <table className="table clients-list-table">
                    <thead className="thead-light clients-list-thead">
                      <tr>
                        <th>COMPANY NAME</th>
                        <th>DATE</th>
                        <th>USER NAME</th>
                        <th>CONTACT PERSON</th>
                        <th>LOCATION</th>
                        <th>MEETING TYPE</th>
                        <th>MOT</th>
                        <th>PURPOSE</th>
                        <th>REMARKS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                          <SkeletonClientHistory />
                        </>
                      ) : clientHistoryDetails !== "" ? (
                        clientHistoryDetails
                          .filter(
                            (data) =>
                              meetingType == "" ||
                              data.meetingType.includes(meetingType)
                          )
                          .map((data, i) => (
                            <tr key={i}>
                              <td>
                                <span className=" color-green fz13">
                                  <b>{data?.clientName}</b>
                                </span>
                              </td>
                              <td>{data?.meetingDate}</td>
                              <td>{data?.userFullName} </td>
                              <td>
                                {data?.contactPersonName}
                                <br />
                                {data?.clientContact}
                              </td>
                              <td style={{ maxWidth: "200px" }}>
                                <span className="color-yellow">
                                  <i className="bi bi-geo-alt" />
                                </span>{" "}
                                &nbsp;
                                {data?.clientLocation}
                              </td>
                              <td>{data?.meetingType}</td>
                              <td>{data?.travelBy}</td>
                              <td>{data?.purpose}</td>
                              <td style={{ maxWidth: "150px" }}>
                                <OverlayTrigger
                                  placement={"left"}
                                  delay={{ show: 250, hide: 300 }}
                                  overlay={
                                    <Tooltip id={`tooltip-remarks`}>
                                      {data?.remarks}
                                    </Tooltip>
                                  }
                                >
                                  <div>{data?.lessRemarks}</div>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={10}>
                            ------ NO DATA FOUND ------
                          </td>
                        </tr>
                      )}
                      {loading && <OnScrollLoading />}
                    </tbody>
                  </table>
                </div>
                <div className="download-and-pagination">
                  <SbDownloadBtn
                    btnName="Download"
                    onClickEffect={exportUserHistory}
                  />
                </div>
              </div>
              {/* USER TABLE END */}

              {/* PAGE CONTENT END*/}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ClientHistory;
